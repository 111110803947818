.logOutButton:hover {
    cursor: pointer;
}

.logOutButton:focus-visible {
    outline: 2px solid var(--color-blue-d) !important;
    outline-offset: 0.2rem !important;
}


.headerLinkMenu > img {
    margin-bottom: 1px;
}

.headerLinkMenu {
    transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}

.headerLinkMenu:hover > span {
    border-bottom: 1px solid white;
}
.headerLinkMenu:hover > img {
    border-bottom: 1px solid white;
}

.headerLinkMenu:disabled > span {
    border:none;
}

.headerLinkMenu:disabled > img {
    border:none;
}

.headerLinkMenu:focus-visible {
    outline: 2px solid white !important;
}

.headerLinkMenu:focus-visible > img {
    border-bottom: 1px solid white;
}
.headerLinkMenu:focus-visible > span {
    border-bottom: 1px solid white;
}

.pdfIconHoverButton:hover > .headerIcoPdf {
    display: none;
}
.pdfIconHoverButton > .headerIcoPdfHover {
    display: none;
}
.pdfIconHoverButton:hover > .headerIcoPdfHover {
    display: inline;
}
.pdfIconHoverButton:focus-visible > .headerIcoPdf {
    display: none;
}
.pdfIconHoverButton:focus-visible > .headerIcoPdfHover {
    display: inline;
}

.headerLogoLink:focus-visible {
    outline:none !important;
}

.headerLogoLink:focus-visible > img {
    outline: 2px solid var(--color-blue-d) !important;
    outline-offset: 0.2rem !important;
}

.headerAccordionMenuTrigger:focus-visible {
    outline: 2px solid white !important;
    outline-offset: -0.85rem !important;
}