:root {
  --color-bk: #161616;
  --color-w: #fff;
  --color-gray: #F5F5F5;
  --color-blue-l: #D2E0FF;
  --color-blue-m: #1C4EC1;
  --color-blue-d: #283960;
  --color-red-m: #CB0000;
  --color-main-conversion: #D14600;
  --color-micro-conversion: #FFCC00;
  --color-main-conversion-light: #ffe7db;
  --color-micro-conversion-light: #fff0b3;
  --round: 0.8rem;
  --round-m: 1.6rem;
  --content-padding: clamp(2rem, 1.76rem + 1.2vw, 3.2rem);
  --inner-padding: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
  --border: #9B9B9B;
  --small: 1.2rem;
}

:focus-visible {
  outline: 2px solid var(--color-blue-d);
  outline-offset: 0.2rem;
}

html {
  font-size: 62.5%;
}

body {
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-bk);
  background: #F3F6FE;
  line-height: 1.6;
  font-size: 1.6rem;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.small {
  font-size: var(--small);
}

.bold {
  font-weight: bold;
}

.gap_24 {
  gap: 2.4rem;
}

.gap_40 {
  gap: 4rem;
}

/*@media (max-width: 960px) {*/
/*  .sp {*/
/*    display: block;*/
/*  }*/
/*  .pc {*/
/*    display: none;*/
/*  }*/
/*}*/
/*@media (min-width: 961px) {*/
/*  .sp {*/
/*    display: none;*/
/*  }*/
/*}*/

.appContainer {
  display: flex;
  flex-direction: column;
  gap:0;
  min-height: 100vh;
}

.mainArea {
  flex: 1 !important;
}

.container {
  flex-grow: 1;
}
.container.top {
  padding: 0;
}
.container.lower {
  width: 1440px;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}
@media (max-width: 1439px) {
  .container.lower {
    padding: 0 var(--content-padding) 2.4rem;
  }
}
@media (max-width: 960px) {
  .container.lower {
    flex-direction: column;
    padding: 0 0 2.4rem;
  }
}

.content_wrap {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.list_wrap ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
.list_wrap ul li {
  flex-basis: 9.5rem;
  border: 1px solid var(--border);
  background: var(--color-w);
  font-size: 1.4rem;
}
@media (max-width: 500px) {
  .list_wrap ul li {
    flex-basis: calc(33.3333333333% - 0.7rem);
  }
}
.list_wrap ul li a {
  display: block;
  text-align: center;
  padding: 0.4rem;
  box-sizing: border-box;
  transition: 0.3s;
}
.list_wrap ul li a:hover {
  background: var(--color-blue-l);
}

a.underline {
  text-decoration: underline;
}
a.underline:hover {
  font-weight: 500;
}
a .material-symbols-outlined {
  vertical-align: middle;
  font-size: 0.9em;
}

span {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

em {
  font-weight: bold;
}

button:hover {
  cursor: pointer;
}

h2 {
  font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
  line-height: 1.4;
  font-weight: bold;
  border-bottom: 2px solid var(--color-blue-m);
  padding-bottom: 0.8rem;
}

h3 {
  font-size: clamp(1.6rem, 1.52rem + 0.4vw, 2rem);
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 0.8rem;
  border-left: 4px solid var(--color-blue-m);
  padding-left: 0.8rem;
  line-height: 1;
}

h4 {
  font-weight: bold;
  font-size: clamp(1.6rem, 1.56rem + 0.2vw, 1.8rem);
  line-height: 1.4;
}

h5 {
  font-weight: bold;
}

.section_title {
  background-color: var(--color-blue-m);
  color: var(--color-w);
  padding: 1.6rem;
  position: relative;
  margin-bottom: 6.4rem;
}
.section_title h2 {
  border: none;
  text-align: center;
}
.section_title h2 span:not(.br) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section_wrap {
  padding: 0 0 clamp(4rem, 3.2rem + 4vw, 8rem);
}

.section_inner {
  --width-sec: 1440px;
  margin: auto;
  max-width: var(--width-sec);
  width: 100%;
}
@media (max-width: 960px) {
  .section_inner {
    padding: 0 var(--inner-padding);
  }
}
.section_inner.w960 {
  --width-sec: 960px;
}

.btn {
  text-align: center;
}
.btn a {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  padding: 0.8rem 1.6rem 0.8rem 1rem;
  display: inline-flex;
  color: var(--color-bk);
  border: 1px solid var(--btn-color);
  background: var(--btn-color);
  transition: 0.1s;
  align-items: center;
  line-height: 1;
  justify-content: center;
  gap: 0.4rem;
}
.btn a::before, .btn a::after {
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  display: block;
  font-size: 1.2em;
}
.btn.conversion a {
  --btn-color: var(--color-main-conversion);
  color: var(--color-w);
}
.btn.conversion a::before {
  content: "\e7fd";
  font-size: 1.4em;
}
.btn.conversion a:hover, .btn.conversion a:focus {
  background: var(--color-main-conversion-light);
  color: var(--color-bk);
}
.btn.micro_conversion a {
  --btn-color: var(--color-micro-conversion);
}
.btn.micro_conversion a:hover, .btn.micro_conversion a:focus {
  background: var(--color-micro-conversion-light);
}
.btn.micro_conversion a::before {
  content: "\e8b6";
  font-size: 1.4em;
}
.btn.function a, .btn.otherbtn a {
  background: var(--color-w);
  color: var(--color-bk);
  border: 1px solid var(--color-blue-d);
}
.btn.function a:hover, .btn.function a:focus, .btn.otherbtn a:hover, .btn.otherbtn a:focus {
  background: var(--color-gray);
}
.btn.function.f_download a::before, .btn.otherbtn.f_download a::before {
  content: "\f090";
  font-size: 1.4em;
}
.btn.function.f_login a::before, .btn.otherbtn.f_login a::before {
  content: "\ea77";
  font-size: 1.4em;
}
.btn.function.f_mail a::before, .btn.otherbtn.f_mail a::before {
  content: "\e158";
  font-size: 1.4em;
}
.btn.otherbtn a {
  padding: 0.8rem 1rem 0.8rem 1.6rem;
}
.btn.otherbtn a::after {
  content: "\e5cc";
  font-size: 1.4em;
}

.crumb {
  padding: clamp(1.6rem, 3vw, 2.4rem);
}
.crumb ol {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 1.4rem;
}
.crumb ol li:not(:last-child)::after {
  font-size: 1.4rem;
  content: ">";
  margin: 0 0.8rem;
  display: inline-block;
}
.crumb ol a {
  display: inline-block;
  text-decoration: underline;
}

.content {
  padding: var(--content-padding);
  background: var(--color-w);
  border-radius: var(--round);
}
@media (max-width: 960px) {
  .content {
    border-radius: 0;
  }
}

.anchor {
  background: var(--color-w);
}
.anchor ul {
  max-width: 960px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.anchor ul li {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.anchor ul a {
  text-align: center;
  display: block;
  padding: 2.4rem 1.6rem 1.6rem;
  font-weight: bold;
  flex: 1;
  width: 100%;
  font-size: clamp(1.2rem, 1.12rem + 0.4vw, 1.6rem);
}
@media (max-width: 960px) {
  .anchor ul a {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 1.6rem 0;
  }
  .anchor ul a span {
    display: block;
  }
}
@media (max-width: 420px) {
  .anchor ul a {
    font-size: 3vw;
    padding: 1.6rem 0 1.2rem;
  }
}
.anchor ul a::after {
  content: "\e313";
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  display: block;
  font-size: 2em;
  color: var(--color-blue-m);
  transition: 0.1s;
  line-height: 1;
}
.anchor ul a:hover::after {
  transform: translateY(0.5rem);
}

.main_conversion {
  margin: clamp(3.2rem, 2.88rem + 1.6vw, 4.8rem) 0;
  padding: 0 2rem;
}
.main_conversion_wrap {
  background: var(--color-blue-m);
  padding: var(--content-padding) 2rem;
  position: relative;
  max-width: 1680px;
  margin: auto;
}
.main_conversion_wrap::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
  background: rgba(168, 184, 220, 0.34);
}
.main_conversion_inner {
  background: var(--color-w);
  padding: var(--inner-padding);
  border-radius: var(--round);
  max-width: 960px;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: auto;
}
.main_conversion_inner h2 {
  border: none;
  text-align: center;
}
.main_conversion .conversion_list {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem 2.4rem;
}
@media (max-width: 560px) {
  .main_conversion .conversion_list {
    flex-direction: column;
  }
  .main_conversion .conversion_list .btn {
    width: 100%;
  }
  .main_conversion .conversion_list .btn a {
    width: 100%;
  }
}
.main_conversion_inner_m30 {
  background: var(--color-w);
  padding: var(--inner-padding);
  border-radius: var(--round);
  max-width: 960px;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 30px auto;
}

/* ///////////////
    header
//////////////// */
header {
  position: sticky;
  top: 0;
  z-index: 4;
  border-bottom: 2px solid var(--color-blue-m);
}
header .header_inner {
  background: var(--color-w);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 1.6rem 2.4rem;
  flex-wrap: wrap;
  gap: 0.8rem;
}
@media (max-width: 960px) {
  header .header_inner {
    padding: 1.4rem;
  }
}
header .logo a {
  display: flex;
  align-items: center;
}
@media (max-width: 960px) {
  header .logo {
    width: 16rem;
  }
}
header .user_btn {
  display: flex;
  gap: 1.6rem;
  align-items: center;
}
@media (max-width: 960px) {
  header .user_btn {
    justify-content: flex-end;
  }
  header .user_btn .conversion {
    display: none;
  }
  header .user_btn .f_login a {
    font-size: 1.4rem;
    padding: 0;
    border: none;
    background: none;
  }
  header .user_btn .f_login a:hover {
    text-decoration: underline;
    background: none;
  }
}
header nav {
  background: var(--color-blue-d);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2.4rem;
  padding: 0.8rem 1.6rem;
}
@media (max-width: 960px) {
  header nav {
    flex-direction: column;
    padding: 1.6rem;
  }
}
header nav li {
  display: flex;
  align-items: flex-end;
}
header nav li a {
  display: block;
  font-weight: bold;
  color: var(--color-w);
  padding: 0.8rem 1.6rem;
  transition: 0.2s;
}
@media (max-width: 960px) {
  header nav li a {
    width: 100%;
  }
}
header nav li a:hover, header nav li a:focus {
  text-decoration: underline;
}
@media (max-width: 960px) {
  header nav li a:hover, header nav li a:focus {
    text-decoration: none;
    background: var(--color-gray);
    color: var(--color-bk);
  }
}
header .header_nav .mainmenu_wrap {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 960px) {
  header .header_nav .mainmenu_wrap {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
header .nav_btn {
  display: flex;
  gap: 1.6rem;
}
header .nav_btn .btn a {
  border-color: var(--color-w);
  color: var(--color-w);
  background: var(--color-blue-d);
}
header .nav_btn .btn a:hover, header .nav_btn .btn a:focus {
  color: var(--color-bk);
}
@media (max-width: 960px) {
  header .nav_btn {
    width: 100%;
    flex-direction: column;
  }
  header .nav_btn .btn {
    flex: 1;
  }
  header .nav_btn .btn a {
    width: 100%;
  }
  header .nav_btn .btn a:hover, header .nav_btn .btn a:focus {
    background: none;
  }
}
header .sp_menu_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0.4rem;
  border-radius: var(--round);
  transition: 0.2s;
}
@media (min-width: 1100px) {
  header .sp_menu_btn {
    display: none;
  }
}
header .sp_menu_btn:hover, header .sp_menu_btn:focus {
  cursor: pointer;
  background: var(--color-gray);
}
header .sp_menu_btn span {
  position: relative;
  display: block;
  width: 2rem;
  height: 2px;
  margin: auto;
  background-color: var(--color-blue-d);
  transition: 100ms;
}
header .sp_menu_btn span.open {
  transform: rotate(45deg);
}
header .sp_menu_btn span.open::before {
  transform: rotate(90deg) translateX(0.8rem);
  transform-origin: center;
}
header .sp_menu_btn span.open::after {
  transform: scale(0);
  transform-origin: center;
}
header .sp_menu_btn span::before {
  position: absolute;
  top: -0.8rem;
  display: block;
  width: 2rem;
  height: 2px;
  content: "";
  background-color: var(--color-blue-d);
}
header .sp_menu_btn span::after {
  position: absolute;
  top: 0.8rem;
  display: block;
  width: 2rem;
  height: 2px;
  content: "";
  background-color: var(--color-blue-d);
}
header .sp_conversion {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0 0.8rem 0.8rem;
  background: var(--color-w);
}
@media (min-width: 961px) {
  header .sp_conversion {
    display: none;
  }
}
header .sp_conversion .btn {
  flex: 1;
}
header .sp_conversion .btn a {
  width: 100%;
  font-size: 1.4rem;
}
@media (max-width: 960px) {
  header .sp_wrap {
    display: none;
    position: absolute;
    z-index: -1;
    width: 100%;
  }
}

/* ///////////////
    footer
//////////////// */
footer {
  background: var(--color-blue-d);
  color: var(--color-w);
  position: relative;
  font-size: 1.4rem;
  margin-top: 8rem;
}
footer .footer_inner {
  max-width: 1440px;
  margin: auto;
  padding: 3.2rem 2.4rem;
}
footer .footer_main {
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-w);
  margin-bottom: 1.6rem;
}
footer .footer_main h2 {
  font-size: 1.6rem;
  border: none;
}
footer .footer_main ul {
  display: flex;
  justify-content: flex-start;
  gap: 0.8rem 3.2rem;
  flex-wrap: wrap;
}
footer .footer_main ul a:hover {
  font-weight: 500;
}
footer .footer_sub {
  text-align: right;
}
footer .footer_sub ul {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem 1.6rem;
  flex-wrap: wrap;
}
footer .pagetop {
  right: 0;
}
footer .pagetop a {
  background: var(--color-blue-d);
  color: var(--color-w);
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
}

/*amplify linkタグのフォーカス*/
.amplify-link:focus-visible {
  outline: 2px solid var(--color-blue-d) !important;
  outline-offset: 0.2rem !important;
}

/*各ボタンの配色・ホバー・フォーカス*/
.orangeHoverButton {
  border:1px solid #D14600 !important;
  transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}
.orangeHoverButton:focus-visible {
  color: black !important;
  background-color: #ffe7db !important;
  outline: 2px solid var(--color-blue-d) !important;
  outline-offset: 0.2rem !important;
}
.orangeHoverButton:hover {
  color: black !important;
  background-color: #ffe7db !important;
}
.orangeHoverButton:disabled {
  color: hsl(210, 10%, 58%) !important;
  background-color: #D14600 !important;
  border:none;
  pointer-events: none;
}

.orangeHoverButtonDarkblueBackground:focus-visible {
  outline: 2px solid white !important;
  outline-offset: 0.2rem !important;
}

.greenHoverButton {
  transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}

.greenHoverButton:hover {
  color: black !important;
  background-color: hsl(130, 60%, 90%) !important;
  border-color: #00bf8b !important;
}

.greenHoverButton[disabled]  {
  pointer-events: none;
}

.greenHoverButton:focus-visible {
  color: black !important;
  background-color: hsl(130, 60%, 90%) !important;
  border-color: #00bf8b !important;
  outline: 2px solid var(--color-blue-d) !important;
  outline-offset: 0.2rem !important;
}

.whiteHoverButton {
  transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}

.whiteHoverButton:hover {
  background-color: #F5F5F5 !important;
}

.whiteHoverButton:focus-visible {
  background-color: #F5F5F5 !important;
  outline: 2px solid var(--color-blue-d) !important;
  outline-offset: 0.2rem !important;
  box-shadow: none !important;
}

.whiteToBlueHoverButton {
  transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}

.whiteToBlueHoverButton:hover {
  background-color: #D2E0FF !important;
}

.whiteToBlueHoverButton:focus-visible {
  background-color: #D2E0FF !important;
  outline: 2px solid var(--color-blue-d) !important;
  outline-offset: 0.2rem !important;
}

.whiteToBlueHoverButton:disabled {
  background-color: #F5F5F5 !important;
  pointer-events: none;
}

.darkblueHoverButton:hover {
  color: black !important;
  background-color: hsl(220, 85%, 85%) !important;
  border-color:#283960;
}

.darkblueHoverMenu {
  color: white !important;
  background-color: #283960 !important;
}

.darkblueHoverMenu:hover {
  color: black !important;
  background-color: white !important;
}

.darkblueHoverMenu:focus-visible {
  color: black !important;
  background-color: white !important;
}

.darkblueHoverMenu:disabled {
  color: hsl(210, 10%, 58%) !important;
  background-color: #283960 !important;
}

.darkblueHoverButtonDarkblueBackground {
  transition: all var(--amplify-time-medium), outline, outline-offset 0s !important;
}

.darkblueHoverButtonDarkblueBackground:focus-visible {
  outline: 2px solid white !important;
  outline-offset: 0.2rem !important;
}

.footerLink:hover{
  border-bottom: 0.5px solid white;
}

.footerLink:focus-visible{
  outline: 2px solid white !important;
  outline-offset: 0.2rem !important;
  border-bottom: 1px solid white;
}

