/*一覧表*/
.filterlist-table th, .filterlist-table tr {
    padding: 0.5rem;
}

.filterlist-table .right{
    text-align:right;
}

.filterlist-table .left{
    text-align:left;
}

.filterlist-table .amplify-table__td{
    word-break : break-all;
}

.alertSearchFilter {
  flex-grow: 0;
  padding: 0; /* 余白を無くす */
  width: 100%;
  max-width: 1445px;
}