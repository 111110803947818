.sub_content_title .title_inner {
  background: var(--color-blue-l);
  padding: clamp(2.4rem, 1.76rem + 3.2vw, 5.6rem) 2rem;
}
.sub_content_title .title_inner h1 {
  font-size: clamp(2.8rem, 2.56rem + 1.2vw, 4rem);
  text-align: center;
  font-weight: bold;
  color: var(--color-blue-d);
}

.sec_wrap {
  padding: 0 0 clamp(4rem, 3.2rem + 4vw, 8rem);
}

.List li,
.List dd {
  text-indent: -0.6em;
  margin-left: 0.6em;
}
.List li::before,
.List dd::before {
  content: "";
  display: inline-block;
  height: 0.4rem;
  width: 0.4rem;
  background: var(--color-blue-d);
  border-radius: 50%;
  margin-right: 0.4rem;
  vertical-align: middle;
}

.section_content {
  border-radius: var(--round-m);
  max-width: 960px;
  margin: auto;
  overflow: hidden;
}
.section_content h3 {
  background: var(--color-blue-m);
  color: var(--color-w);
  padding: var(--inner-padding);
  margin: 0;
  line-height: 1.5;
  font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
}
.section_content h4 {
  margin-bottom: 0.8rem;
}
.section_content .content_flex {
  background: var(--color-w);
  padding: var(--content-padding);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2.4rem;
}
@media (max-width: 960px) {
  .section_content .content_flex {
    flex-direction: column;
  }
}
.section_content .txt {
  width: 50%;
}
@media (max-width: 960px) {
  .section_content .txt {
    width: 100%;
  }
}
.section_content .txt p:not(:last-of-type) {
  margin-bottom: 1.6rem;
}
.section_content .img {
  width: calc(50% - 2.4rem);
}
@media (max-width: 960px) {
  .section_content .img {
    width: 100%;
  }
}
.section_content .img .btn {
  margin-top: 1.6rem;
}
.section_content .small {
  text-align: right;
}

.fap_wrap {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.fap_inner {
  background: var(--color-w);
  border: 2px solid var(--color-blue-m);
  border-radius: var(--round);
}
.fap_inner button {
  padding: 1.6rem;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: space-between;
}
.fap_inner button::after {
  content: "";
  display: block;
  top: 0;
  right: 0;
  height: 1.2rem;
  width: 2.4rem;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  background: var(--color-blue-d);
  transition: 0.3s;
}
.fap_inner button.active::after {
  transform: rotate(180deg);
}
.fap_inner .answer_wrap {
  border-top: 1px dashed var(--border);
  padding: 1.6rem;
}