/*プルダウン*/
.num-display .amplify-select{
    font-size:1.5rem;
    padding:1rem 4rem 0.8rem 2.5rem;
}

.num-display .amplify-select__icon{
    font-size:2rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

/*履歴表*/
.dlhistory-table th, .dlhistory-table tr {
    padding: 0.5rem;
}

.dlhistory-table th {
    font-weight: bold;
}

.dlhistory-table .center{
    text-align:center;
}

.dlhistory-table .right{
    text-align:right;
}

.dlhistory-table .left{
    text-align:left;
}

.dlhistory-table .middle{
    vertical-align:middle;
}

.dlhistory-table .amplify-table__td{
    word-break : break-all;
}



/* ページネーション */
.searchPaginationDLLog .amplify-button--link{
    font-size: 1.5rem;
    border-radius: 10%;
    border-width: 0.1rem;
    border-color: black;
    padding: 2rem 1.5rem;
    margin: 0rem 0.5rem;
}

/*ページ番号*/
.searchPaginationDLLog .amplify-pagination__item {
    color: black;
    background-color: white;
}
.searchPaginationDLLog .amplify-pagination__item:hover {
    color: white;
    background-color: #1C4EC1;
}
.searchPaginationDLLog .amplify-pagination__item:focus-visible {
    color: white;
    background-color: #1C4EC1;
    outline: 2px solid var(--color-blue-d);
    outline-offset: 0.2rem;
}

.searchPaginationDLLog .amplify-pagination__item:hover .st0{
    fill:white;
}

/*表示中のページ番号*/
.searchPaginationDLLog .amplify-pagination__item--current {
    color: white;
    fill:white;
    background-color: #1C4EC1;
}

.searchPaginationDLLog .amplify-pagination__item--current .st0{
    fill:white;
}

.alertDLLog {
  flex-grow: 0;
  padding: 0; /* 余白を無くす */
  width: 100%;
  max-width: 1445px;
}