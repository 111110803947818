.dlmodal-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.dlmodal-table {
  width: 100%;
}

/*ダウンロードモダール検索条件*/
.dlmodal-table th, .dlmodal-table tr {
    padding: 1rem;
}

.dlmodal-table th {
    font-weight: bold;
}

.dlmodal-table .right{
    text-align:right;
}

.dlmodal-table .left{
    text-align:left;
}

.dlmodal-table .middle{
    vertical-align:middle;
}

.dlmodal-table .amplify-table__td{
    word-break : break-all;
}
/*ダウンロードモダール　DLボタン*/
.amplify-button .dlmodal-icon .st1{
    fill:white;
}

.amplify-button:hover .dlmodal-icon .st1{
    fill:black;
}

.table-containerCsvDL {
  max-height:250px;
  overflow-y: scroll;
  position: relative;
  display: inline-block; 
}

.table-containerCsvDL thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table-containerCsvDL thead::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px; /* borderの高さ */
  background-color: lightgray; /* borderの色 */
}
