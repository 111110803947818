/*お知らせのHTMLコンテンツ*/
.homeinfo-htmlcontent > p {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 10px;
}
@media screen and (min-width: 1100px) {
    .homeinfo-htmlcontent > p {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 10px;
    }
}

/*並び替えのプルダウン*/
.numorder-pulldown .amplify-select{
    font-size: 1.25rem;
    padding:0.7rem 2.5rem 0.7rem 2rem;
}
.kindorder-pulldown .amplify-select{
    font-size: 1.25rem;
    padding:0.7rem 2rem 0.7rem 1.5rem;
}

/*検索結果*/
.result-table .amplify-checkboxfield{
    align-items: center;
}

.result-table .right{
    text-align:right;
}

/*アイコンの色塗*/
.whiteHoverButton .st0{
     fill: #1C4EC1;
}

.greenHoverButton .st1{
    fill:white;
}

.greenHoverButton:hover .st1{
    fill:black;
}

.greenHoverButton:focus-visible .st1{
    fill:black;
}

/* ページネーション */
.searchPaginationHome .amplify-button--link{
    font-size: 1.5rem;
    border-radius: 10%;
    border-width: 0.1rem;
    border-color: black;
    padding: 2rem 1.5rem;
    margin: 0rem 0.5rem;
}
/* 読込中のページネーション */
.searchPaginationHome[disabled] .amplify-button--link{
    pointer-events: none;
    color: hsl(210, 10%, 58%) !important;
    background-color: hsl(210, 5%, 94%) !important;
}

/* 画面幅が768px以下の場合（タブレット） */
@media screen and (max-width: 768px) {
    .searchPaginationHome .amplify-button--link {
        font-size: 1.4rem;
        padding: 1.5rem 1rem;
        margin: 0rem 0.4rem;
    }
}

/* 画面幅が425px以下の場合（スマートフォン） */
@media screen and (max-width: 425px) {
    .searchPaginationHome .amplify-button--link {
        font-size: 1.3rem;
        padding: 1.2rem 0.8rem;
        margin: 0rem 0.3rem;
    }
}


/*ページ番号*/
.searchPaginationHome .amplify-pagination__item {
    color: black;
    background-color: white;
}
.searchPaginationHome .amplify-pagination__item:hover {
    color: white;
    background-color: #1C4EC1;
}

/*表示中のページ番号*/
.searchPaginationHome .amplify-pagination__item--current {
    color: white;
    background-color: #1C4EC1;
}

.amplify-checkbox__button::before {
    background-color: white !important;
}

.amplify-checkbox__button[data-checked="true"]::before {
    background-color: var(--amplify-colors-blue-60) !important;
}

.amplify-checkbox__button--disabled::before {
    background-color: #F5F5F5 !important;
}

.amplify-input.amplify-field-group__control:disabled {
    background-color: #F5F5F5 !important;
    border-color: hsl(210, 5%, 87%) !important;
}


/*ダウンロードモダール　DLボタン*/
.amplify-button .dl-icon .st1{
    fill:white;
}


/*お知らせ/データ更新アコーディオン*/
.HomeInfoAccordion:focus-visible {
    outline: 2px solid var(--color-blue-d);
    outline-offset: 0.2rem;
}


.detailConditionRadioButton:disabled .amplify-radio__button::before {
    background-color: transparent !important;
}

.detailConditionRadioChoices > .amplify-radio__button::after {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 0px;
    position: absolute;
}

.detailConditionRadioChoices > input:focus-visible + .amplify-radio__button::after {
    outline: 2px solid var(--color-blue-d) !important;
    outline-offset: 0.1rem !important;
}

/*詳細条件iマーク ツールチップ*/
.notAllowDetailConditionInputIndication {
    position: relative;
    display: flex;
    align-items: center;
}
.notAllowDetailConditionInputIndication__tooltip {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4.25rem;
    padding: 0.5rem;
    white-space: nowrap;
    font-size: 1.2rem;
    line-height: 2.5rem;
    background-color: var(--color-blue-d);
    color: white;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0.2rem 0.2rem gray;;
}

.notAllowDetailConditionInputIndication > img:hover + .notAllowDetailConditionInputIndication__tooltip {
    opacity: 1;
    visibility: visible;
}

/*スマホサイズでの折り返し*/
span {
   display: inline-block;
}

.alertHome {
  flex-grow: 0;
  padding: 0; /* 余白を無くす */
  width: 100%
}

 .searchPaginationHome .amplify-pagination__item:focus-visible {
     color: white;
	 background-color: #1C4EC1;
     outline: 2px solid var(--color-blue-d);
     outline-offset: 0.2rem;
 }
 
 .LinkInHomeScreen:focus-visible {
     outline: 2px solid var(--color-blue-d);
     outline-offset: 0.2rem;
 }
 
 .SearchFilterAccordion:focus-visible {
    outline: 2px solid var(--color-blue-d);
    outline-offset: 0.2rem;
}

.plusMinusDisplaySwitchButton {
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

.plusMinusDisplaySwitchButton:focus-visible > img {
    outline: 2px solid var(--color-blue-d) !important;
    outline-offset: 0.1rem !important;
}