.sub_content_title {
  margin-bottom: 4rem;
}

.lead {
  margin-bottom: 4rem;
}

.features .section_content h3 {
  display: flex;
  align-items: center;
  gap: 0.8rem 1.6rem;
}
@media (max-width: 960px) {
  .features .section_content h3 {
    flex-direction: column;
    align-items: flex-start;
  }
}
.features .section_content h3 span {
  font-size: clamp(1.4rem, 1.2rem + 1vw, 2.4rem);
  background: var(--color-w);
  border-radius: 2.4rem;
  padding: 0.8rem 1.6rem;
  color: var(--color-blue-m);
}

.howto_wrap {
  display: flex;
  flex-direction: column;
  gap: 5.6rem;
}
.howto_title {
  text-align: center;
  margin: 0 auto 5.6rem;
  width: 30rem;
  max-width: 100%;
}
.howto_title h3 {
  width: 100%;
  display: inline-block;
  background: var(--color-blue-d);
  color: var(--color-w);
  text-align: center;
  border-radius: var(--round);
  padding: 1.6rem;
  border: none;
}
.howto_title .arrow {
  margin-top: -2.4rem;
  display: block;
}
.howto_title .arrow::before, .howto_title .arrow::after {
  content: "\e5c5";
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  display: block;
  font-size: 5.6em;
  color: var(--color-blue-d);
  line-height: 1;
  margin-bottom: -6.4rem;
}
.howto_detail {
  background: var(--color-w);
  border-radius: var(--round-m);
  padding: var(--content-padding);
}
.howto_detail .example {
  background: var(--color-blue-d);
  display: inline-block;
  padding: 0.4rem 1.6rem;
  border-radius: var(--round) var(--round) 0 0;
}
.howto_detail .example p {
  color: var(--color-w);
  display: inline;
}
.howto_list {
  border-radius: 0 var(--round) var(--round) var(--round);
  border: 2px solid var(--color-blue-d);
  padding: 1.2rem 1.6rem 1.6rem;
  background: #f3f6fe;
  margin-bottom: 2.4rem;
}
.howto_list li {
  border-bottom: 1px dashed var(--border);
  padding: 0.8rem;
  font-weight: bold;
  line-height: 1.5;
  align-items: center;
  display: flex;
  gap: 0.8rem;
}
.howto_list li::before {
  content: "\e0ee";
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  display: block;
  font-size: 1.8em;
  color: var(--color-blue-d);
  transition: 0.1s;
  line-height: 1;
}
.howto_filter {
  display: flex;
  background: var(--color-w);
  justify-content: space-between;
  gap: 1.6rem;
}
.howto_filter ul {
  background: var(--color-gray);
  padding: 1.6rem;
  border-radius: var(--round);
  flex: 1;
}
@media (max-width: 960px) {
  .howto_filter {
    flex-direction: column;
  }
}
.howto_flex {
  display: flex;
  background: var(--color-w);
  align-items: flex-start;
  justify-content: space-between;
  gap: 2.4rem;
}
@media (max-width: 960px) {
  .howto_flex {
    flex-direction: column-reverse;
  }
}
.howto_flex .txt,
.howto_flex .img {
  flex: 1;
  width: 100%;
}
.howto_flex .txt img,
.howto_flex .img img {
  text-align: center;
}
.howto_flex .txt .small,
.howto_flex .img .small {
  text-align: right;
  margin: 0;
}
.howto_flex .txt p,
.howto_flex .img p {
  margin-bottom: 1.6rem;
}

.howto_tab .tablist {
  display: flex;
  align-items: stretch;
}
@media (max-width: 960px) {
  .howto_tab .tablist {
    flex-direction: column;
    gap: 1.6rem;
    margin: 0.8rem 0 2.4rem;
  }
}
.howto_tab .tablist li {
  flex: 1;
  display: flex;
}
.howto_tab .tablist li button {
  width: 100%;
  padding: 1.6rem;
  border-radius: var(--round-m) var(--round-m) 0 0;
  font-size: clamp(1.6rem, 1.56rem + 0.2vw, 1.8rem);
  text-align: center;
  font-weight: bold;
  background: #d9dbe1;
  color: #646464;
  border: 1px solid var(--border);
}
@media (max-width: 960px) {
  .howto_tab .tablist li button {
    padding: 0.8rem 1.6rem;
    border-radius: 0;
  }
}
.howto_tab .tablist li button .font_large {
  font-size: clamp(1.6rem, 1.44rem + 0.8vw, 2.4rem);
}
.howto_tab .tablist li button.active {
  border: 1px solid var(--color-blue-m);
  background: var(--color-blue-m);
  color: var(--color-w);
}
.howto_tab .tab_content {
  border-radius: 0 0 var(--round-m) var(--round-m);
  display: none;
  border: 2px solid var(--color-blue-m);
  overflow: hidden;
}
@media (max-width: 960px) {
  .howto_tab .tab_content {
    border-radius: var(--round-m);
  }
}
.howto_tab .tab_content.show {
  display: block;
}
.howto_tab .tab_content h3 {
  background: var(--color-blue-m);
  color: var(--color-w);
  text-align: center;
  padding: var(--inner-padding);
  margin: 0;
  line-height: 1.5;
  font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
}