aside {
  width: 36rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
@media (max-width: 960px) {
  aside {
    width: 100%;
  }
}
aside .aside_content {
  background: var(--color-w);
  border-radius: var(--round);
  gap: 5rem;
}
aside .aside_content h3 {
  border: none;
  display: flex;
  align-items: center;
  line-height: 1.5;
  gap: 1.6rem;
  font-size: 1.8rem;
  justify-content: flex-start;
}
aside .aside_content .btn {
  width: 100%;
}
aside .aside_content .btn a {
  width: 100%;
}
aside .aside_inner {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
aside .aside_conversion {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
aside .aside_micro_conversion {
  border: 1px solid var(--color-blue-m);
}
aside .aside_micro_conversion h3 {
  padding: 0.8rem 1.6rem;
  color: var(--color-w);
  font-size: 1.6rem;
  background: var(--color-blue-m);
  margin: 0;
}
aside .aside_micro_conversion h4 {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.8rem;
}
aside .aside_micro_conversion .txt {
  padding: 1.6rem;
}
aside .aside_micro_conversion .txt p {
  margin-bottom: 1.6rem;
}
aside hr {
  border-top: 1px solid var(--border);
}
aside .sns_share {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
@media (max-width: 960px) {
  aside .sns_share {
    padding: 0 2rem;
  }
}
aside .sns_share_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}

main {
  width: calc(100% - 36rem - 4rem);
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}
@media (max-width: 960px) {
  main {
    width: 100%;
  }
}

.tag {
  font-size: 1.4rem;
  display: inline-block;
  border: 1px solid var(--border);
  padding: 0 0.8rem;
  margin: 0 0.2rem;
}

table {
  border-collapse: collapse;
}

.table table {
  border-collapse: collapse;
  width: 100%;
}
.table tr {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}
.table th {
  border-right: 1px solid var(--border);
  font-weight: bold;
  width: 0;
  white-space: nowrap;
}
@media (max-width: 960px) {
  .table th {
    border-right: none;
    border-bottom: 1px solid var(--border);
    background: var(--color-gray);
  }
}
.table th,
.table td {
  padding: 0.4rem 0.8rem;
}
@media (max-width: 960px) {
  .table th,
  .table td {
    display: block;
    width: 100%;
  }
}
.table td a {
  text-decoration: underline;
}

.database.nodatabase table,
.database.nodatabase .table_btn {
  display: none;
}
.database .data_tltle {
  display: flex;
  gap: 0.2rem;
  align-items: baseline;
}
.database .data_tltle p {
  font-size: 1.4rem;
}
.database table {
  font-size: 1.4rem;
  width: 100%;
  line-height: 1.4;
  margin-bottom: 0.8rem;
  table-layout: fixed;
  word-break: break-all;
}
/*.database tr:nth-child(n+3) {*/
/*  display: none;*/
/*}*/
/*.database.more tr:nth-child(n+3), .database.all tr:nth-child(n+3) {*/
/*  display: table-row;*/
/*}*/
.database th {
  font-weight: bold;
  background: var(--color-gray);
}
.database th,
.database td {
  border: 1px solid var(--border);
  padding: 0.4rem;
}
@media (max-width: 960px) {
  .database:not(.patent) thead {
    display: none;
  }
  .database:not(.patent) tbody {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .database:not(.patent) td {
    display: flex;
    justify-content: flex-start;
    gap: 1.6rem;
    margin-top: -1px;
  }
  .database:not(.patent) td p {
    width: calc(100% - 7em - 1.6rem);
  }
  .database:not(.patent) td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 7em;
  }
}
.database .table_btn {
  border: 1px solid var(--border);
  background: var(--color-gray);
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.database .table_btn span {
  display: flex;
  width: 1.4rem;
  height: 0.4rem;
  background: var(--color-blue-m);
  align-items: center;
  justify-content: center;
}
.database .table_btn span::after {
  content: "";
  display: block;
  height: 1.4rem;
  width: 0.4rem;
  background: inherit;
  transition: 0.3s;
}
.database .table_btn span.more::after {
  transform: rotate(90deg);
}

.content {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  gap: 2.4rem;
  flex-direction: row;
}
@media (min-width: 1281px) {
  .flex.flex_start {
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
@media (max-width: 1280px) {
  .flex {
    flex-direction: column;
  }
}
.flex .flex_inner {
  flex: 1;
}

.pager ul {
  display: flex;
  justify-content: space-between;
}
.pager ul a {
  text-decoration: underline;
}
.pager ul a.prev::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 10px 7px 0;
  border-color: transparent var(--color-bk) transparent transparent;
  margin-right: 0.4rem;
}
.pager ul a.next::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 10px;
  border-color: transparent transparent transparent var(--color-bk);
  margin-left: 0.4rem;
}

.anchor_detail {
  padding: 0 2.4rem;
}
.anchor_detail ul{
  display: flex;
  justify-content: flex-start;
  gap: 0.8rem 1.6rem;
  flex-wrap: wrap;
}
.anchor_detail a{
  text-decoration: underline;
}
.anchor_detail a:hover ,.anchor_detail_side a:hover{
  font-weight: 500;
}
.anchor_detail a:before{
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  font-size: 2em;
  content: "\e5c5";
  display: inline-block;
  vertical-align: middle;
  letter-spacing: -0.1em;
}
.anchor_detail_side ul {
  display: flex;
  justify-content: flex-start;
  gap: 1.6rem;
  flex-wrap: wrap;
  flex-direction: column;
}

.anchor_detail_side-item {
  display: flex;
  gap: 0.5rem;
  align-items: center
}


.list_wrap {
  background: var(--color-gray);
  padding: var(--inner-padding);
  border-radius: var(--round);
}
.list_wrap.businnes_type li {
  flex-basis: auto;
}

.icon-underline{
  border-bottom: black solid 1px;
}

/*概要*/
.company_about {
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .company_about {
    flex-direction: column-reverse;
  }
}
.company_about .txt {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
.company_about .txt .business_type span {
  font-weight: bold;
}
.company_about .txt h1 {
  font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
  line-height: 1.4;
  font-weight: bold;
}
.company_about .txt h2 {
  border: none;
  font-size: 1.6rem;
}
.company_about .txt .company_industry {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.company_about .company_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  border: 1px solid var(--color-bk);
  flex-basis: 28rem;
  width: 100%;
  height: 16rem;
}

.sidemenu-icon {
  display: flex;
  gap:1rem;
}

.sidemenu-icon .st1 {
  fill: #1C4EC1;
}

.relation-icon {
  display: flex;
  justify-content: center;
  border: solid #1C4EC1 2px;
  border-radius: 10rem;
  padding: 1.2rem;
}

.finance .graph_area {
  width: 100%;
  border: 1px solid var(--border);
  padding: 0.4rem;
}

.history table {
  width: auto;
}
.history h4 {
  font-size: 1.6rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.8rem;
}
.history h4 .history_date {
  font-weight: 400;
  background: var(--color-blue-d);
  color: var(--color-w);
  border-radius: 1.4rem;
  padding: 0.2rem 0.8rem;
  font-size: 1.4rem;
}
.history .history_list li:not(:last-child)::after {
  content: "";
  display: block;
  height: 4.8rem;
  width: 2px;
  background: var(--color-blue-d);
  margin: 1.6rem 4rem;
}

.other .near_company {
  background: var(--color-gray);
  padding: var(--inner-padding);
  border-radius: var(--round);
}
.other .near_company ul {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.other .near_company ul a {
  display: flex;
  gap: 1.6rem;
  align-items: center;
  background: var(--color-w);
  padding: 1.6rem 2.4rem;
  transition: 0.3s;
}
.other .near_company ul a:hover {
  background: var(--color-blue-l);
}
@media (max-width: 1280px) {
  .other .near_company ul a {
    flex-direction: column;
    align-items: flex-start;
  }
}
.other .near_company ul a .near_company_inner {
  flex: 1;
}
.other .near_company ul a .near_name {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
@media (max-width: 1280px) {
  .other .near_company ul a .near_name {
    align-items: baseline;
  }
}
.other .near_company ul a .near_name p {
  flex: 1;
}
.other .near_company ul a .near_name::before {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  border-bottom: 2px solid var(--color-blue-m);
  border-right: 2px solid var(--color-blue-m);
  transform: rotate(-45deg);
}
.other .near_company ul a .near_info {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.other .near_company ul a dl {
  display: flex;
  gap: 0.4rem;
  line-height: 1.4;
}
@media (max-width: 500px) {
  .other .near_company ul a dl {
    flex-direction: column;
    align-items: flex-start;
  }
}
.other .near_company ul a dl dt {
  border-radius: 1.4rem;
  padding: 0.2rem 0.8rem;
  font-size: 1.2rem;
  border: 1px solid var(--color-bk);
  min-width: 4em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
}

.company_data .database.patent table {
  width: auto;
  border: 1px solid var(--border);
}
.company_data .database.patent th {
  background: none;
  text-align: center;
  font-weight: 400;
}
.database .right {
  text-align: right;
}
@media (max-width: 960px) {
  .database .right {
    text-align: left;
  }
}
.company_data .database.patent td {
  padding: 1.2rem;
}
.company_data .database.patent td span {
  font-size: 2em;
}
.company_data .database .ministry {
  min-width: 4em;
}
.company_data .database .notification_date {
  width: 10em;
}
.company_data .database.qualifications .type {
  min-width: 6em;
}
.company_data .database.qualifications .level {
  min-width: 5em;
}
.company_data .graph_area {
  width: 100%;
  border: 1px solid var(--border);
  padding: 0.4rem;
}

@media (max-width: 960px) {
  .event {
    padding: 0 2rem;
  }
}
.event .event_content a {
  border: 1px solid var(--color-bk);
  display: block;
  border-radius: var(--round);
  background: var(--color-w);
  overflow: hidden;
}
.event .event_content a:hover {
  background-color: var(--color-gray);
}
.event .event_content a img {
  object-fit: cover;
}
.event .event_content a .event_txt {
  padding: 1.2rem;
}
.event .event_content a .event_txt .event_day {
  font-weight: bold;
  font-size: 1.2rem;
}
.event .event_content a .event_txt .event_ttl {
  font-size: 1.4rem;
  font-weight: bold;
}
.event .event_content a .event_txt .event_about {
  font-size: 1.2rem;
}

.mask {
  position: relative;
}
.mask .mask_data {
  position: relative;
}
.mask .mask_data td {
  filter: blur(2px);
  position: relative;
  user-select: none;
}
.mask .mask_data td:before {
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  display: block;
}
.mask_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.4);
}
.mask_inner {
  background: var(--color-blue-m);
  color: var(--color-w);
  padding: var(--inner-padding);
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  justify-content: center;
}
.mask_inner a.mask_login {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  border-left: none;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: bold;
}
.mask_inner a.mask_login:hover {
  color: var(--color-blue-l);
}
.mask_inner a.mask_login .material-symbols-outlined {
  display: block;
  font-size: 5rem;
}
.mask_inner hr {
  background: var(--color-w);
  height: 1px;
  width: 100%;
}
.mask_inner p {
  text-align: center;
  font-size: 1.2rem;
}
.mask_inner .btn {
  margin: auto;
}
.mask_inner .btn a {
  background: var(--color-blue-l);
}
.mask_inner .btn a:hover {
  background: var(--color-w);
}

#Businnes.status_logout .logout_link a {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
}
#Businnes.status_logout .logout_link a::before {
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  display: block;
  content: "\ea77";
  font-size: 1.2em;
}
#Businnes.status_logout .logout_link a span {
  text-decoration: underline;
}

.relation {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
@media (max-width: 1040px) {
  .relation {
    padding: 0 var(--inner-padding);
  }
}
.relation_inner {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.relation .relation_btn {
  display: flex;
  border-radius: var(--round);
  border: 1px solid var(--color-blue-m);
  background: var(--color-w);
  justify-content: space-between;
  overflow: hidden;
}
@media (max-width: 1040px) {
  .relation .relation_btn {
    flex-direction: column;
  }
}
.relation .relation_btn:hover {
  background: var(--color-gray);
}
.relation_txt {
  padding: 2rem var(--inner-padding);
  display: flex;
  gap: 2rem;
  align-items: center;
  width: calc(100% - 20rem);
}
@media (max-width: 1040px) {
  .relation_txt {
    width: 100%;
    flex-direction: column;
  }
}
.relation_txt h3 {
  border: none;
  color: var(--color-blue-m);
  margin-bottom: 0.8rem;
  line-height: 1.5;
}
.relation_link {
  background: var(--color-blue-m);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  padding: 1.6rem;
  font-size: 1.4rem;
  color: var(--color-w);
  font-weight: bold;
  width: 20rem;
  box-sizing: content-box;
}
.relation_link.view_all {
  justify-content: center;
}
@media (max-width: 1040px) {
  .relation_link {
    width: 100%;
    justify-content: center;
  }
}
.relation_link .material-symbols-outlined {
  display: block;
  font-size: 2.8rem;
}
.relation_conversion {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}
.relation_conversion .btn {
  flex: 1;
}
.relation_conversion .btn a {
  width: 100%;
  padding: 1.6rem;
}

.magnifying_glass {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 0.6em;
  color: #FFFFFF;
  font-size: 20px;
  margin: 0.1em 0.2em 0.2em 0.5em;
}

.magnifying_glass::before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0.7em;
  height: 0.7em;
  box-sizing: border-box;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  background-color: #00BF8B;
  content: "";
}

.magnifying_glass::after {
  position: absolute;
  top: 8px;
  left: 66%;
  z-index: 66;
  transform: rotate(-45deg);
  width: 0.15em;
  height: 0.65em;
  box-sizing: border-box;
  background-color: #FFFFFF;
  content: "";
}


@media(min-width: 501px) {
  .chart-container {
    position: relative;
    width: 420px;
    height: 210px;
  }

  .graph_area {
    position: relative;
    height: 210px;
  }
}

@media(max-width: 500px) {
  .chart-container {
    position: relative;
    width: 100%;
    height: 175px;
  }

  .graph_area {
    position: relative;
    height: 175px;
  }
}

.graph_area canvas{
  width: 100%;
  height: 100%;
}