input {
    appearance: revert;
}

.callmodal-table th, .callmodal-table tr {
    padding: 1rem;
}

.callmodal-table th {
    font-weight: bold;
}

.callmodal-table .right{
    text-align:right;
}

.callmodal-table .left{
    text-align:left;
}

.callmodal-table .middle{
    vertical-align:middle;
}

.callmodal-table .amplify-table__td{
    word-break : break-all;
}

/*アイコンの色塗*/
.whiteHoverButton .st0{
  fill: #1C4EC1;
}

.greenHoverButton .st1{
    fill:white;
}

.greenHoverButton:hover .st1{
    fill:black;
}

.table-containerCall {
  max-height: 510px;
  overflow-y: scroll;
  position: relative;
}

.table-containerCall thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
