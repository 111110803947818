.changeplan-agreement {
	font-size: 87.5%;
}

.changeplan-agreement h4 {
	margin: 0 0 1em 0;
	padding: 0;
	border-bottom: 0;
}

.changeplan-agreement h4:not(:first-child) {
	margin-top: 1.5em;
}

.changeplan-agreement h5 {
	margin: 1em 0 .75em 0;
	padding: 0;
	background: none;
	border-left: 0;
	color: var(--color-bk);
	font-size: 1.5rem;
}

.changeplan-agreement h6 {
	margin: 0 0 .2em 0;
  	font-weight: bold;
}

.changeplan-agreement p {
	margin: 0 0 .75em 0;
}

.changeplan-agreement ol,
.changeplan-agreement ul {
	margin-bottom: 1em;
	padding-left: 1.75em;
}

.changeplan-agreement ol {
	list-style: decimal;
}

.changeplan-agreement ul {
	list-style: disc;
}

.changeplan-agreement li *:last-child {
	margin-bottom: 0;
}

.changeplan-agreement li {
	margin-bottom: 0.5em;
}

.changeplan-agreement ol.brackets {
  margin: .5em 0 1.5em 0;
	padding: 0;
}

.changeplan-agreement ol.brackets li {
	position: relative;
	padding-left: 2.25em;
	list-style: none;
}

.changeplan-agreement ol.brackets li span {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 2em;
	padding-right: .25em;
	text-align: right;
}

.changeplan-agreement ol.brackets li span:has(+ h6) {
	display: inline-block;
	margin-bottom: .2em;
	font-weight: bold;
	line-height: 1.4;
}

.changeplan-agreement ol.brackets li span:has(+ h6) + li {
	margin-top: 1em;
}

.changeplan-agreement dt {
	margin: 1em 0;
	font-size: 100%;
	font-weight: bold;
}