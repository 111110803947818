.table-cell {
  word-wrap: break-word;   /* 単語がセルの幅を超えた場合に自動改行 */
  word-break: break-all;   /* 単語の途中でも強制改行 */
  white-space: pre-wrap;   /* 必要に応じて改行や折返しを許可 */
  overflow: hidden;        /* オーバーフローコンテンツを隠す */
  text-overflow: ellipsis; /* 省略記号を表示 */
  max-width: 100%;         /* 最大幅をセル内に制限 */
}

/*アイコンの色塗*/
.whiteHoverButton .st0{
  fill: #1C4EC1;
}

.greenHoverButton .st1{
    fill:white;
}

.greenHoverButton:hover .st1{
    fill:black;
}

.savemodal-table th, .savemodal-table tr {
    padding: 1rem;
}

.savemodal-table th {
    font-weight: bold;
}

.savemodal-table .right{
    text-align:right;
}

.savemodal-table .left{
    text-align:left;
}

.savemodal-table .middle{
    vertical-align:middle;
}

.savemodal-table .amplify-table__td{
    word-break : break-all;
}

.table-containerSave {
  max-height:300px;
  overflow-y: scroll;
  position: relative;
  display: inline-block; 
}

.table-containerSave thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table-containerSave thead::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px; 
  background-color: lightgray; 
}

 .LinkInModal:focus-visible {
     outline: 2px solid var(--color-blue-d);
     outline-offset: 0.2rem;
 }