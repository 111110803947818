.service_request {
  background: var(--color-gray);
  border-radius: var(--round);
  display: flex;
  padding: 1.6rem;
  gap: 1.6rem;
  align-items: center;
  margin-bottom: 2.4rem;
}
.service_request ul {
  flex: 3;
}
.service_request img {
  flex: 1;
}

.price_list {
  max-width: 1200px;
  margin: auto;
}

.changeplan_table {
  width: 100%;
}
@media (min-width: 1100px) {
  .changeplan_table {
    border-spacing: 16px 0;
    border-collapse: separate !important;
  }
}
.changeplan_table .changeplan_thead .plan {
  font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
  border-radius: var(--round-m) var(--round-m) 0 0;
  background: var(--color-w);
  padding: 1.6rem;
  text-align: center;
  font-weight: bold;
}
@media (min-width: 1100px) {
  .changeplan_table .changeplan_thead .plan {
    width: 25%;
  }
}
.changeplan_table .changeplan_thead .plan.free {
  background: var(--color-blue-m);
  color: var(--color-w);
}
.changeplan_table .changeplan_thead .plan.plus, .changeplan_table .changeplan_thead .plan.basic {
  border: 2px solid var(--border);
}
.changeplan_table .changeplan_tbody .changeplan_tr:nth-child(even) {
  background: #ededed;
}
.changeplan_table .changeplan_tbody .changeplan_tr:nth-child(odd) {
  background: var(--color-w);
}
@media (min-width: 1100px) {
  .changeplan_table .changeplan_tbody .changeplan_tr:last-of-type .free {
    border-bottom: 6px solid var(--color-blue-m);
  }
  .changeplan_table .changeplan_tbody .changeplan_tr:last-of-type .plus,
  .changeplan_table .changeplan_tbody .changeplan_tr:last-of-type .basic {
    border-bottom: 2px solid var(--border);
  }
}
.changeplan_table .changeplan_tbody .changeplan_tr.price_search .changeplan_th {
  vertical-align: top;
}
.changeplan_table .changeplan_tbody .changeplan_th {
  font-weight: bold;
  font-size: clamp(1.6rem, 1.56rem + 0.2vw, 1.8rem);
  vertical-align: middle;
}
@media (min-width: 1100px) {
  .changeplan_table .changeplan_tbody .changeplan_th {
    width: 20%;
    text-align: center;
  }
}
.changeplan_table .changeplan_tbody .changeplan_th span {
  font-size: 1.2rem;
  font-weight: normal;
  display: block;
}
@media (min-width: 1100px) {
  .changeplan_table .changeplan_tbody .changeplan_td.free {
    border-left: 6px solid var(--color-blue-m);
    border-right: 6px solid var(--color-blue-m);
  }
  .changeplan_table .changeplan_tbody .changeplan_td.plus, .changeplan_table .changeplan_tbody .changeplan_td.basic {
    border-left: 2px solid var(--border);
    border-right: 2px solid var(--border);
  }
}
@media (min-width: 1100px) {
  .changeplan_table .changeplan_td,
  .changeplan_table .changeplan_th {
    padding: 1.6rem;
  }
}
.changeplan_table .changeplan_td span,
.changeplan_table .changeplan_th span {
  font-weight: bold;
}
.changeplan_table .plan_price .changeplan_td {
  font-weight: bold;
  text-align: center;
  font-size: clamp(2rem, 1.84rem + 0.8vw, 2.8rem);
  vertical-align: middle;
}
.changeplan_table .plan_price .changeplan_td span {
  font-size: 0.6em;
}
.changeplan_table .plan_price .free {
  color: var(--color-red-m);
  font-size: clamp(4rem, 3.2rem + 4vw, 8rem);
}
.changeplan_table .price_search .changeplan_dl {
  margin-bottom: 2.4rem;
}
.changeplan_table .price_search .changeplan_dl .changeplan_dt {
  font-weight: bold;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid var(--border);
}
.changeplan_table .price_search .changeplan_dl .changeplan_dd {
  padding: 0.4rem 0;
}

@media (max-width: 1099px) {
  .price_list_col_inner h3 {
    font-size: clamp(2rem, 1.92rem + 0.4vw, 2.4rem);
    border-radius: var(--round-m) var(--round-m) 0 0;
    background: var(--color-w);
    padding: 1.6rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
  }
  .price_list_col_inner h3.free {
    background: var(--color-blue-m);
    color: var(--color-w);
  }
  .price_list_col_inner h3.plus, .price_list_col_inner h3.basic {
    border: 2px solid var(--border);
  }
  .price_list_col {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 var(--inner-padding);
  }
  .changeplan_tr,
  .changeplan_th,
  .changeplan_td {
    display: block;
    width: 100%;
  }
  .changeplan_tr {
    padding: 1.6rem;
  }
  .changeplan_th {
    border-bottom: 1px solid var(--border);
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .price_list_free .changeplan_tbody .changeplan_tr {
    border-left: 6px solid var(--color-blue-m);
    border-right: 6px solid var(--color-blue-m);
  }
  .price_list_free .changeplan_tbody .changeplan_tr:last-of-type {
    border-bottom: 6px solid var(--color-blue-m);
  }
  .price_list_plus .changeplan_tbody .changeplan_tr,
  .price_list_basic .changeplan_tbody .changeplan_tr {
    border-left: 2px solid var(--border);
    border-right: 2px solid var(--border);
  }
  .price_list_plus .changeplan_tbody .changeplan_tr:last-of-type,
  .price_list_basic .changeplan_tbody .changeplan_tr:last-of-type {
    border-bottom: 2px solid var(--border);
  }
}
.price_micro_conversion h4 {
  text-align: center;
  margin-bottom: 1.6rem;
}
.price_micro_conversion .btn {
  margin: auto;
  width: 48rem;
  max-width: 100%;
}
.price_micro_conversion .btn a {
  width: 100%;
}

.pointer-cursor {
    cursor: pointer;
}